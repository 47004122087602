import {IFilter} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {IFilterOption} from '../types/galleryTypes';

export const mergeNameAndTypeSharedOptionAndModifierFilters = (filters: IFilter[]) => {
  const mergedFilters = [];

  filters.forEach((filter) => {
    const existingFilter = mergedFilters.find((f) => f.name === filter.name && f.filterType === filter.filterType);

    if (!existingFilter) {
      const newFilter = {
        filterType: filter.filterType,
        name: filter.name,
        field: filter.field,
        values: filter.values.map((value) => ({
          ...value,
        })),
      };
      mergedFilters.push(newFilter);
    } else {
      filter.values.forEach((value) => {
        const existingValue = existingFilter.values.find((v) => v.value === value.value);

        if (existingValue) {
          existingValue.sources = existingValue.sources || {[existingFilter.field]: existingValue.id};
          existingValue.sources[filter.field] = value.id;
        } else {
          const newValue = {
            ...value,
            sources: {[filter.field]: value.id},
          };
          existingFilter.values.push(newValue);
        }
      });

      existingFilter.values.forEach((value) => {
        if (!value.sources) {
          value.sources = {[existingFilter.field]: value.id};
        }
      });
    }
  });

  return mergedFilters;
};

export const getValuesWithFilterSources = (activeOptions: string[], options: IFilterOption[]) =>
  activeOptions
    .map((optionKey) => {
      const matchingOption = options.find((optionObj) => optionObj.key === optionKey);
      const entries = Object.entries(matchingOption.sources);
      return entries.map(([filterId, choiceId]) => `${filterId.replace('options.', '')}#${choiceId}`);
    })
    .flat();
