/* eslint-disable import/no-cycle */
import type {IFilterDTO, IFilterModel, IFilterOption} from '../types/galleryTypes';
import {FilterEqOperation, FilterType} from '../types/galleryTypes';
import {getValuesWithFilterSources} from '../services/customizationFiltersService';

export class ListFilterModel implements IFilterModel {
  public title: string;
  public options: IFilterOption[];
  public filterId: number;
  public activeOptions: string[] = [];
  public selectedOptions: string[] = [];

  private readonly FILTER_FIELD = 'optionChoices';

  constructor(
    public readonly filterType: FilterType.LIST_OPTION,
    title: string,
    options: IFilterOption[],
    private readonly field: string
  ) {
    this.title = title;
    this.options = options;
  }

  public updateActiveOptions(optionId: string): void {
    if (!this.activeOptions.includes(optionId)) {
      this.activeOptions.push(optionId);
    } else {
      const index = this.activeOptions.indexOf(optionId);
      this.activeOptions.splice(index, 1);
    }
  }

  public toDTO(): IFilterDTO {
    const shouldReplaceOption = this.field.startsWith('options.');
    const optionName = shouldReplaceOption ? this.field.replace('options.', '') : this.field;
    let values;

    const optionsHasSources = this.options.some((option) => option.sources);

    if (optionsHasSources) {
      values = getValuesWithFilterSources(this.activeOptions, this.options);
    } else {
      values = this.activeOptions.map((optionKey) => {
        const matchingOption = this.options.find((optionObj) => optionObj.key === optionKey);
        return matchingOption.id ?? optionKey;
      });
      if (shouldReplaceOption) {
        values = values.map((optionIdentifier) => `${optionName}#${optionIdentifier}`);
      }
    }
    return {
      field: shouldReplaceOption ? this.FILTER_FIELD : this.field,
      op: FilterEqOperation.IN,
      values,
    };
  }

  /* istanbul ignore next: filter is not supported for external data source yet */
  public toExternalDTO() {
    return null;
  }

  public hasActiveOptions(): boolean {
    return !!this.activeOptions.length;
  }

  public resetActiveOptions(): void {
    this.activeOptions = [];
  }
}
