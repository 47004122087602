/* eslint-disable import/no-cycle */
import {FilterEqOperation, IFilterDTO, IFilterModel, IFilterOption, FilterType} from '../types/galleryTypes';
import {FILTER_FIELD} from '../services/CollectionFiltersService';

export class CollectionFilterModel implements IFilterModel {
  public title: string;
  public options: IFilterOption[];
  public activeOptions: string;
  public filterId: number;

  constructor(
    public readonly filterType: FilterType.COLLECTION,
    title: string,
    options: IFilterOption[],
    private readonly mainCollectionId: string
  ) {
    this.title = title;
    this.options = options;
    this.activeOptions = mainCollectionId;
  }

  public updateActiveOptions(optionId: string): void {
    this.activeOptions = optionId;
  }

  public hasActiveOptions(): boolean {
    return this.activeOptions !== '';
  }

  public resetActiveOptions(): void {
    this.updateActiveOptions(this.mainCollectionId);
  }

  public isAffectingQuery(): boolean {
    return this.activeOptions !== this.mainCollectionId;
  }

  public toDTO(): IFilterDTO {
    return {
      field: FILTER_FIELD,
      op: FilterEqOperation.IN_ALL,
      values: this.activeOptions as any,
    };
  }

  /* istanbul ignore next: filter is not supported for external data source yet */
  public toExternalDTO() {
    return null;
  }
}
